import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Navigation } from "./Navigation";
import {
  Box,
  Card,
  CardMedia,
  Container,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { AuthContext } from "../Services/AuthContext";
import { CopyrightComponent } from "./CopyrightComponent";
import { useContext } from "react";
import Title from "./Title";
import { useLocation } from "react-router-dom";
import { FingerPrintList } from "./FingerPrintList";
import NavigationIcon from "@mui/icons-material/Navigation";
import { ImageZoomControls } from "./ImageZoomControls";
import { IFingerRequest } from "../type";
import { hasFingerPrintListingAccess } from "../common/hasFingerPrintListingAccess";

const mdTheme = createTheme();

export const CustomerDetails = () => {
  const data = useLocation();
  const item = data.state;
  //const [aadharDetail, viewAadhar] = React.useState<IFingerRequest>();
  const [isShowImage, setImagePage] = React.useState(false);
  const { authtoken, setAuthenticated } = useContext(AuthContext);
  const [image, viewLargeImage] = React.useState();
  const role = localStorage.getItem("role");
  const canShowFingerList = role && hasFingerPrintListingAccess(parseInt(role));
  const viewAadhar = (src: string) => {
    window.open(src);
  };
  return (
    <div>
      <AuthContext.Provider value={{ authtoken, setAuthenticated }}>
        <ThemeProvider theme={mdTheme}>
          {!isShowImage && (
            <Box sx={{ display: "flex" }}>
              <Navigation />
              <Box
                component="main"
                maxWidth={"lg"}
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
                  flexGrow: 1,
                  height: "100vh",
                  overflow: "auto",
                }}
              >
                <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
                  <Title>Customer Details</Title>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={8}>
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          minHeight: 300,
                        }}
                      >
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                              >
                                Name
                              </TableCell>
                              <TableCell align="right">{item?.name}</TableCell>
                            </TableRow>
                            <TableRow />
                            <TableRow>
                              <TableCell align="left">Mobile</TableCell>
                              <TableCell align="right">
                                {item?.mobile}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="left">Aadhar Number</TableCell>
                              <TableCell align="right">
                                {item?.aadhar_number}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="left">DOB</TableCell>
                              <TableCell align="right">{item?.dob}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="left">Notes</TableCell>
                              <TableCell align="right">{item?.notes}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="left">
                                Registeration Date
                              </TableCell>
                              <TableCell align="right">
                                {item?.created_at}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Paper>
                    </Grid>
                    {/* Recent Deposits */}
                    <Grid item xs={12} md={4} lg={4}>
                      <Card sx={{ maxWidth: 245 }}>
                        <CardMedia
                          sx={{ height: 200 }}
                          image={
                            item?.customer_photo ||
                            "/android-chrome-192x192.png"
                          }
                        />
                      </Card>

                      <Box sx={{ "& > :not(style)": { m: 1 } }}>
                        {item.customer_photo && (
                            <Fab
                                onClick={() => viewAadhar(item.customer_photo)}
                                variant="extended"
                                size="small"
                                color="primary"
                                aria-label="add"
                            >
                              <NavigationIcon sx={{ mr: 1 }} />
                             Customer Photo
                            </Fab>
                        )}
                        {item.aadhar_number_front && (
                          <Fab
                            onClick={() => viewAadhar(item.aadhar_number_front)}
                            variant="extended"
                            size="small"
                            color="primary"
                            aria-label="add"
                          >
                            <NavigationIcon sx={{ mr: 1 }} />
                            Aadhar Card
                          </Fab>
                        )}
                        {item.birth_doc && (
                            <Fab
                                onClick={() => viewAadhar(item.birth_doc)}
                                variant="extended"
                                size="small"
                                color="primary"
                                aria-label="add"
                            >
                              <NavigationIcon />
                              Birth Docs
                            </Fab>
                        )}
                        {item.aadhar_number_back && (
                          <Fab
                            onClick={() => viewAadhar(item.aadhar_number_back)}
                            variant="extended"
                            size="small"
                            color="primary"
                            aria-label="add"
                          >
                            <NavigationIcon />
                            Other Docs 1
                          </Fab>
                        )}
                        {item.other_doc && (
                            <Fab
                                onClick={() => viewAadhar(item.other_doc)}
                                variant="extended"
                                size="small"
                                color="primary"
                                aria-label="add"
                            >
                              <NavigationIcon />
                              Other Docs 2
                            </Fab>
                        )}

                      </Box>

                    </Grid>
                    {/* Recent Orders */}
                    {canShowFingerList && (
                      <Grid item xs={12}>
                        <Paper
                          sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Title>Finger Prints</Title>
                          <FingerPrintList
                            viewLargeImage={viewLargeImage}
                            setImagePage={setImagePage}
                            fingerprintLists={item.fingers.sort(
                              (a: any, b: any) => {
                                return a.finger_number - b.finger_number;
                              }
                            )}
                            isCustomSrc={true}
                          />
                        </Paper>
                      </Grid>
                    )}
                  </Grid>
                  <CopyrightComponent sx={{ pt: 4 }} />
                </Container>
              </Box>
            </Box>
          )}

          {isShowImage && (
            <ImageZoomControls
              customerName={item?.name}
              setImagePage={setImagePage}
              item={image}
              fingers={item.fingers.sort((a: any, b: any) => {
                return a.finger_number - b.finger_number;
              })}
            />
          )}
        </ThemeProvider>
      </AuthContext.Provider>
    </div>
  );
};
